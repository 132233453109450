import React, { useState, useEffect, useRef } from "react";
import "./drawingboard.scss";

import { Box, Flex, Tag, Button } from "@chakra-ui/core";
import { connect } from "react-redux";

import { TwitterPicker } from "react-color";

import CanvasDraw from "./CanvasDraw";
import { peerService } from "../../services/peerService.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Menu, MenuButton, MenuList } from "@chakra-ui/core";

import { useTranslation } from "react-i18next";

const colors = [
  "#540D6E",
  "#EE4266",
  "#FFD23F",
  "#0EAD69",
  "#3BCEAC",
  "#F78DA7",
  "#000000",
  "#795548",
  "#ff0000",
  "#ff6900",
  "#194d33",
  "#0693E3",
  "#ABB8C3",
  "#FFFFFF",
];

function randColor(){
  return colors[Math.floor(Math.random() * (colors.length-2))]
}

const ColorPicker = (props) => {
  const { t } = useTranslation();

  const [displayColorPicker, setDisplay] = useState(false);

  const handleClick = () => {
    setDisplay(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplay(false);
  };

  const handleChange = (color) => {
    handleClose();
    props.onChange(color);
  };

  return (
    <Box>
      <Menu placement="bottom-start" isOpen={displayColorPicker}>
        <MenuButton
          title={t("Select color")}
          className="paintToolButton colorButton"
          as={Button}
          onClick={handleClick}
        >
          <span style={{ backgroundColor: props.color.hex }}></span>
        </MenuButton>
        <MenuList borderRadius="1em" zIndex="30">
          <TwitterPicker
            color={props.color}
            onChange={handleChange}
            triangle="hide"
            colors={colors}
          />
        </MenuList>
      </Menu>
    </Box>
  );
};

const newLineListener = (lineId, brushColor, brushRadius, firstPoint) => {
  peerService.sendToAll({
    type: "draw",
    subtype: "new_line",
    lineId: lineId,
    brushColor: brushColor,
    brushRadius: brushRadius,
    firstPoint: firstPoint,
  });
};

const pointerMoveListener = (lineId, point) => {
  peerService.sendToAll({
    type: "draw",
    subtype: "peer_point",
    lineId: lineId,
    point: point,
  });
};

const lineEndListener = (lineId) => {
  peerService.sendToAll({
    type: "draw",
    subtype: "line_end",
    lineId: lineId,
  });
};

const sendClear = () => {
  peerService.sendToAll({
    type: "draw",
    subtype: "clear",
  });
};

const DrawingBoard = (props) => {
  const { t } = useTranslation();

  const canvasDrawRef = useRef(null);
  const [clearNoti, setClearNoti] = useState({ visible: false, name: "" });

  useEffect(() => {
    function showClearNoti(userid) {
      let user = props.users[userid];
      if (user) {
        Promise.resolve()
          .then(() => {
            setClearNoti({ visible: true, name: user.name });
          })
          .delay(1500)
          .then(() => {
            setClearNoti({ visible: false, name: "" });
          });
      }
    }

    canvasDrawRef.current.onNewLine(newLineListener);
    canvasDrawRef.current.onPointerMove(pointerMoveListener);
    canvasDrawRef.current.onLineEnd(lineEndListener);

    const peerDrawMessageReducer = (message) => {
      switch (message.subtype) {
        case "new_line":
          canvasDrawRef.current.startNewPeerLine(
            message.userid,
            message.lineId,
            message.brushColor,
            message.brushRadius,
            message.firstPoint
          );
          break;
        case "peer_point":
          canvasDrawRef.current.handlePeerPoint(
            message.userid,
            message.lineId,
            message.point
          );
          break;
        case "line_end":
          canvasDrawRef.current.endPeerLine(message.userid, message.lineId);
          break;
        case "clear":
          showClearNoti(message.userid);
          canvasDrawRef.current.peerClear(message.userid);
          break;
        default:
          break;
      }
    };
    peerService.onDrawMessage(peerDrawMessageReducer);
  }, [props.users]);

  const [color, setColor] = useState({
    hex: randColor(),
  });
  const handleColorChange = (color) => {
    setColor(color);
  };

  const handleClearButton = () => {
    canvasDrawRef.current.clear();
    sendClear();
  };

  const [brushRadius, setBrush] = useState(5);
  const handleBrushChange = () => {
    if (brushRadius === 5) setBrush(15);
    else if (brushRadius === 15) setBrush(30);
    else if (brushRadius === 30) setBrush(60);
    else if (brushRadius === 60) setBrush(5);
  };

  let pictionary = props.step.card.type === 1;
  let drawerUser = props.step.presenter;
  let drawingDisabled = pictionary && drawerUser !== props.me.id;

  return (
    <Flex
      className="drawingBoard"
      zIndex="2"
      paddingTop="1rem"
      width="100%"
      height="100%"
    >
      <Flex
        width="4rem"
        alignItems="center"
        className="paintToolBar"
        flexDirection="column"
      >
        <Button
          onClick={props.toggleFullscreen}
          className="paintToolButton"
          fontSize="1.7rem"
          title={t("Toggle wide canvas")}
        >
          <FontAwesomeIcon icon={props.isFullscreen ? "compress" : "expand"} />
        </Button>

        <ColorPicker onChange={handleColorChange} color={color} />

        <Button
          color={color.hex}
          className="paintToolButton"
          fontSize={brushRadius * 2 + "px"}
          title={t("Change brush size")}
          onClick={handleBrushChange}
        >
          <FontAwesomeIcon icon="circle" />
        </Button>
        <Box position="relative">
          <Button
            disabled={drawingDisabled}
            fontSize="1.7rem"
            className="paintToolButton"
            title={t("Clear board")}
            onClick={handleClearButton}
          >
            <FontAwesomeIcon icon="trash-alt" />
          </Button>
          <Box className={"clearNoti " + (clearNoti.visible ? "visible" : "")}>
            <Tag rounded="full">{t("Cleaned", { name: clearNoti.name })}</Tag>
          </Box>
        </Box>
      </Flex>
      <Box width={props.needCollapse && !props.isFullscreen ? "1rem" : "100%"}>
        <CanvasDraw
          onlyOneUser={pictionary}
          onlyUser={drawerUser}
          disabled={drawingDisabled}
          ref={canvasDrawRef}
          className="canvasDraw"
          canvasWidth="100%"
          canvasHeight="100%"
          me={props.me}
          users={props.users}
          brushColor={color.hex}
          brushRadius={brushRadius}
        />
      </Box>
    </Flex>
  );
};

const mapDataToProps = (state) => {
  return {
    me: state.me,
    users: state.room.members,
    step: state.game.step,
  };
};

export default connect(mapDataToProps, null)(DrawingBoard);
