import React, { useState, useEffect, useRef } from "react";

import "./chat.scss";

import { Box, Flex, Button, Input, Text } from "@chakra-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SimpleBar from "simplebar-react";

import Twemoji from "react-emoji-render";

import { connect } from "react-redux";
import { message as messageAction } from "../../redux/actions/actions";

import { quaranTimeClient } from "../../services/quaranTimeClient.js";

import { useTranslation } from "react-i18next";

class ChatItem extends React.Component {
  render() {
    let userid = this.props.message.userid;
    let isMine = userid === this.props.me.id;
    let author = this.props.members[userid];
    let previousMessage = this.props.previousMessage;
    let authorDisplay =
      author &&
      !isMine &&
      (!previousMessage || previousMessage.userid !== userid)
        ? author.name
        : null;
    return (
      <Box>
        {authorDisplay && (
          <Text mb="-3px" p="0 16px" fontSize="xs">
            {authorDisplay}
          </Text>
        )}
        <Flex className={"chatItem " + (isMine ? "mine" : "")} p="4px 10px">
          {/* <Avatar ml="10px" size="sm" src={svg} /> */}
          <Twemoji
            className="chatText"
            onlyEmojiClassName="make-emojis-large"
            text={this.props.message.message}
          />
        </Flex>
      </Box>
    );
  }
}

const ChatFeed = (props) => {
  const messagesEnd = useRef(null);

  const scrollToBottom = () => {
    messagesEnd.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [props.messages]);

  const chatItems = props.messages.map((message, index, array) => (
    <ChatItem
      key={message.id}
      message={message}
      me={props.me}
      previousMessage={array[index - 1]}
      members={props.members}
    />
  ));
  return (
    <SimpleBar
      style={{
        display: "flex",
        justifyContent: "flex-end",
        minHeight: "165px",
        maxHeight: "calc(100vh - 422px)",
        flexDirection: "column",
      }}
    >
      <Flex flexDirection="column" justifyContent="flex-end">
        {/* {chatItems.length ? null : (
            <Text className="welcomeText">
              We need some fun in these times! Connections are between You,
              privacy is assured.
            </Text>
          )} */}
        {chatItems}
      </Flex>
      <div
        id="lol"
        style={{ float: "left", clear: "both", width: 0, height: 0 }}
        ref={messagesEnd}
      ></div>
    </SimpleBar>
  );
};

const mapStateToFeedProps = (state) => {
  return {
    messages: state.messages,
    me: state.me,
    members: state.room.members,
  };
};

const ConnectedChatFeed = connect(mapStateToFeedProps, null)(ChatFeed);

const ChatInput = (props) => {
  const { t } = useTranslation();
  const inputElement = useRef(null);

  const [value, setValue] = useState("");
  const emoji = "😃";

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const send = (message) => {
    if (!message.replace(/\s/g, "").length) return;
    // this.props.messageAction({message:  this.state.value})
    quaranTimeClient.sendMessage({
      message: message,
    });
  };

  const handleEmojiClick = (e) => {
    send(emoji);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      send(value);
      setValue("");
    }
  };

  const handleClick = (e) => {
    inputElement.current.focus()
    send(value);
    setValue("");
  };

  return (
    <Flex left="0" p="10px">
      <Input
        rounded="full"
        spellCheck="false"
        variant="filled"
        type="text"
        placeholder={t("Type a message")}
        aria-label="Search"
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        maxLength="256"
        ref={inputElement}
      />

      <Button
        title={t("Send emoji")}
        onClick={handleEmojiClick}
        rounded="full"
        className="emojiButton"
        variant="ghost"
      >
        {emoji}
      </Button>
      <Button
        title={t("Send message")}
        onClick={handleClick}
        rounded="full"
        className="sendButton"
        variant="ghost"
      >
        <FontAwesomeIcon icon="paper-plane" />
      </Button>
    </Flex>
  );
};

const mapDispatchToProps = {
  messageAction: messageAction,
};

const ConnectedChatInput = connect(null, mapDispatchToProps)(ChatInput);

const Chat = () => (
  <Flex
    flexDirection="column"
    justifyContent="flex-end"
    className="chat"
    flex="1"
  >
    <ConnectedChatFeed />
    <ConnectedChatInput />
  </Flex>
);

export default Chat;
