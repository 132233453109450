import io from "socket.io-client";
import history from "./history";
import store from "../redux/store/store";
import {
  hello,
  message as messageAction,
  user_joined,
  user_left,
  room_update,
  new_room,
  user_update,
} from "../redux/actions/actions";

import { activityClient } from "./activityClient";

const ENDPOINT = process.env.REACT_APP_API_ENDPOINT

export class QuaranTimeClient {
  constructor() {
    let options = {
      path: "/api",
      transports: ["websocket"], // Long polling later ?
      autoConnect: false,
    };

    // If we want to store user data in cookies, doable in store.js too

    let me = store.getState().me;
    if (me.id) {// empty string in store
      options["query"] = { id: me.id };
    }

    this.socket = io(ENDPOINT, options);
    const socket = this.socket;

    // LISTENERS

    socket.on("connect", () => {
      socket.emit("hello", me);
      let roomid = store.getState().room.id;
      if (roomid) {
        this.joinRoom(roomid);
      }
    });

    // socket.on('reconnect', (attemptNumber) => {
    //   console.log("rekónekt")
    //   let roomid = store.getState().room.id;
    //   if(roomid){
    //     this.joinRoom(roomid)
    //   }
    // });

    socket.on("hello", (user) => {
      store.dispatch(hello(user));
    });

    socket.on("new_room", (room) => {
      history.push("/" + room.id, { fromSplash: true }); //SOLVE SPLASH/FIRST LOGIN things
      store.dispatch(new_room(room));
    });

    socket.on("message", (message) => {
      store.dispatch(messageAction(message));
    });

    socket.on("user_joined", (user) => {
      store.dispatch(user_joined(user));
    });

    socket.on("user_left", (userid) => {
      store.dispatch(user_left(userid));
    });

    socket.on("room_update", (room) => {
      store.dispatch(room_update(room));
    });

    socket.on("user_update", (room) => {
      store.dispatch(user_update(room));
    });

    socket.on("peer_data", (userid, data) => {
      store.dispatch({ type: "PEER_DATA", userid: userid, data: data });
    });

    socket.on("disconnect", (reason) => {
      if (reason === "io server disconnect") {
        socket.connect();
      }
      // else socket will automatically reconnect
    });

    //Set gameclients here
    activityClient.setSocketListeners(socket);
  }

  // EMITTERS

  sendMessage(message) {
    let roomid = store.getState().room.id;
    this.socket.emit("message", roomid, message);
  }

  connectSocket() {
    this.socket.connect();
  }

  newRoom() {
    this.socket.emit("new_room");
  }

  joinRoom(roomid) {
    this.socket.emit("join_room", roomid);
  }

  updateUser(user) {
    this.socket.emit("update_user", user);
  }

  signalPeer(peeruserid, data) {
    this.socket.emit("signal_peer", peeruserid, data);
  }
}

export let quaranTimeClient = new QuaranTimeClient();
