import React, { useState, useEffect } from "react";
import "./activityboard.scss";

import { Box, Flex, Image, Text, Button } from "@chakra-ui/core";
import { connect } from "react-redux";
import { activityClient } from "../../services/activityClient.js";

import Zoom from "../../Animations/Zoom";

import ReactCardFlip from "react-card-flip";

import { useTranslation } from "react-i18next";

const charade = "/activity/charade.svg";
const explanatory = "/activity/explanatory.svg";
const pictionary = "/activity/pictionary.svg";

const GameButton = (props) => (
  <Button
    rounded="full"
    size="lg"
    variant="solid"
    fontSize="1rem"
    {...props}
    className={`gameButton ${props.className}`}
  />
);

const Card = (props) => {
  const { t } = useTranslation();

  let [type, img] =
    props.card.type === 1
      ? [t("Pictionary"), pictionary]
      : props.card.type === 2
      ? [t("Explanatory"), explanatory]
      : [t("Charade"), charade];

  const [flipped, setFlipped] = useState(false);

  useEffect(() => {
    if (props.revealed) {
      Promise.resolve()
        .delay(400)
        .then(() => setFlipped(true));
    } else {
      setFlipped(false);
    }
  }, [props.revealed && props.card.word]);

  let pointText = props.isOpenRound ? (
    <Text fontSize="1.1rem">
      {t("Open round")} <br /> {t("2 points for current team")} <br />{" "}
      {t("4 points for winner team")}
    </Text>
  ) : (
    <Text>{t("points", { count: props.card.value || 0 })} </Text>
  );
  return (
    <ReactCardFlip isFlipped={flipped}>
      <Box padding="1rem">
        <Box className="actioncardoutline cardFront">
          <Flex margin="0 1rem" className="actioncard">
            <Box>
              <Text>{type}</Text>
              {pointText}
            </Box>
            <Box>
              <Image marginLeft="1rem" src={img} />
            </Box>
          </Flex>
        </Box>
      </Box>

      <Box className="actioncardoutline cardBack">
        <Box margin="0 1rem" className="actioncard">
          <Flex>
            <Box>
              <Text>{type}</Text>
              {pointText}
            </Box>
            <Box marginLeft="1rem">
              <Image src={img} />
            </Box>
          </Flex>

          <Flex alignItems="center">
            <Text fontSize="1.1rem" marginRight="1rem">
              {t("Word")}
            </Text>
              <Text
                textAlign="center"
                marginBottom="-0.5rem"
                fontSize="2.3rem"
                fontStyle="italic"
              >
                {props.card.word}
              </Text>
          </Flex>
        </Box>
      </Box>
    </ReactCardFlip>
  );
};

const RevealButton = () => {
  const { t } = useTranslation();

  return (
    <GameButton
      onClick={() => {
        activityClient.revealCard();
      }}
      className="revealButton"
    >
      {t("Reveal")}
    </GameButton>
  );
};

const TeamButtons = (props) => {
  const { t } = useTranslation();

  const teamsButtons = props.teams.map((team) => {
    if (team.id === props.currentTeamId && team.players.length === 1)
      return null;
    return (
      <GameButton
        onClick={() => {
          activityClient.setResult({ teamid: team.id });
        }}
        className="teamSuccessButton"
        key={team.id}
      >
        {t("Team", { id: team.name })}
      </GameButton>
    );
  });
  return (
    <Flex flexDirection="column">
      <Text textAlign="center" fontSize="2rem">
        {t("Winner")}
      </Text>
      <GameButton
        onClick={() => {
          activityClient.setResult({ result: "fail" });
        }}
        className="failButton"
        key={-1}
      >
        {t("Fail")} 😐
      </GameButton>
      {teamsButtons}
    </Flex>
  );
};

const ResultButtons = (props) => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection="column">
      <GameButton
        onClick={() => {
          activityClient.setResult({ result: "success" });
        }}
        className="successButon"
      >
        {t("Success")} 💪
      </GameButton>
      <GameButton
        onClick={() => {
          activityClient.setResult({ result: "fail" });
        }}
        className="failButton"
      >
        {t("Fail")} 😐
      </GameButton>
    </Flex>
  );
};

const PresentCard = (props) => {
  const resultButtons = props.isOpenRound ? (
    <Zoom when={props.iPresent && props.revealed}>
      <TeamButtons currentTeamId={props.currentTeamId} teams={props.teams} />
    </Zoom>
  ) : (
    <Zoom when={props.iPresent && props.revealed}>
      <ResultButtons />
    </Zoom>
  );
  return (
    <Flex justifyContent="center" flexWrap="wrap">
      <Card
        revealed={props.revealed || props.iPresent}
        card={props.card}
        isOpenRound={props.isOpenRound}
      ></Card>
      <Box marginLeft="1rem">
        <Zoom when={props.iPresent && !props.revealed}>
          <RevealButton />
        </Zoom>
        {resultButtons}
      </Box>
    </Flex>
  );
};

const ActivityBoard = (props) => {
  const { t } = useTranslation();

  const [textShown, setTextShown] = useState(false);
  const [cardShown, setCardShown] = useState(false);

  let isOpenRound = props.step.isOpenRound;
  let iPresent = props.me.id === props.step.presenter;
  let iGuess = props.me.id === props.step.guesser;

  let presenterName = "";
  if (props.users[props.step.presenter])
    presenterName = props.users[props.step.presenter].name;

  let guesserName = "";
  if (!isOpenRound && props.users[props.step.guesser])
    guesserName = props.users[props.step.guesser].name;

  let text = "";

  if (isOpenRound) {
    if (props.playersCount > 3) {
      text = t("open_exclaim");
    }
    if (iPresent) {
      text += t("present_i");
    } else {
      text += t("present_them", { presenter: presenterName });
    }
  } else {
    if (iPresent) {
      text = t("present_i_to_them", { guesser: guesserName });
    } else if (iGuess) {
      text = t("present_them_to_me", { presenter: presenterName });
    } else {
      text = t("present_them_to_them", {
        presenter: presenterName,
        guesser: guesserName,
      });
    }
  }

  useEffect(() => {
    Promise.resolve()
      .then(() => setCardShown(false))
      .then(() => setTextShown(false))
      .delay(1500)
      .then(() => setTextShown(true))
      .delay(2500)
      .then(() => setTextShown(false))
      .delay(500)
      .then(() => setCardShown(true));
  }, [props.step.round]);

  return (
    <Flex
      className="activityboard"
    >
      <Zoom when={textShown}>
        <Flex justifyContent="center" alignItems="center" whiteSpace="pre-line" height="200px" textAlign="center">
          {text}
        </Flex>
      </Zoom>
      <Zoom when={cardShown}>
        <PresentCard
          card={props.step.card}
          currentTeamId={props.step.currentTeamId}
          revealed={props.step.revealed}
          isOpenRound={props.step.isOpenRound}
          iPresent={iPresent}
          teams={props.teams}
        />
      </Zoom>
    </Flex>
  );
};

const mapDataToProps = (state) => {
  return {
    me: state.me,
    users: state.room.members,
    step: state.game.step,
    teams: state.game.teams,
    playersCount: state.game.playersCount,
  };
};

export default connect(mapDataToProps, null)(ActivityBoard);
