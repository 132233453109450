import React from "react";
import "./videoboard.scss";

import { Flex } from "@chakra-ui/core";
import { connect } from "react-redux";

import Zoom from "../Animations/Zoom";

import VideoBox from "./VideoBox";
import MyVideoBox from "./MyVideoBox";


const VideoBoard = (props) => {
  let weInCall = props.peerStuff.inCall;

  return (
    <Zoom transformOrigin="top" height="initial" width="initial" when={true}>
      <Flex className="videoBoard">
        <MyVideoBox
          key={props.me.id}
          inCall={weInCall}
        ></MyVideoBox>

        {Object.values(props.users).map((user) => {
          if (weInCall && user && props.largeVideoUser !== user.id) {
            let peerUser = props.peerStuff.users[user.id];
            if (peerUser && peerUser.inCall) {
              return (
                <VideoBox
                  key={user.id}
                  user={user}
                  largeVideo={false}
                  toggleLargeVideo={props.toggleLargeVideo}
                ></VideoBox>
              );
            }
          }
          return null;
        })}
      </Flex>
    </Zoom>
  );
};
// flexWrap="nowrap"

const mapStateToProps = (state) => {
  return {
    me: state.me,
    users: state.room.members,
    peerStuff: state.peerStuff,
  };
};

export default connect(mapStateToProps, null)(VideoBoard);
