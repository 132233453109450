import React, { useRef, useState, useEffect } from "react";

import { Flex, Button, Box } from "@chakra-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";

import { peerService } from "../../services/peerService.js"; //Initialization by import

import { useTranslation } from "react-i18next";

import Zoom from "../../Animations/Zoom";
import Jello from "react-reveal/Jello";

import StartVideoCall from "../../VideoBoard/StartVideoCall";

const MyVideoBox = (props) => {
  const { t } = useTranslation();

  let teamid = props.playerTeams[props.me.id];
  let isPresenter = props.step.presenter === props.me.id;

  const videoRef = useRef(null);

  const [voiceMuted, setVoiceMuted] = useState(peerService.voiceMuted);
  const [videoMuted, setVideoMuted] = useState(peerService.videoMuted);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    setVoiceMuted(peerService.voiceMuted);
    setVideoMuted(peerService.videoMuted);
    if (props.inCall && !initialized) {
      if ("srcObject" in videoRef.current) {
        videoRef.current.srcObject = peerService.getMyStream();
      } else {
        videoRef.current.src = window.URL.createObjectURL(
          peerService.getMyStream()
        ); // older browsers
      }
      setInitialized(true);
    } else {
      setInitialized(false);
    }
  }, [props.inCall]);

  const handleVoiceMute = () => {
    peerService.muteVoice(!voiceMuted);
    setVoiceMuted(!voiceMuted);
  };

  const handleVideoMute = () => {
    peerService.muteVideo(!videoMuted);
    setVideoMuted(!videoMuted);
  };

  const handleStopCall = () => {
    peerService.stopCall();
  };

  let [callButtonZIndex, videoControlZIndex] = props.inCall ? [1, 2] : [2, 1];

  return (
    <Flex
      className={
        "videobox mine " +
        "team" +
        (teamid + 1) +
        (isPresenter ? " presenter" : "") +
        (props.inCall ? " inCall" : "")
      }
    >
      <video
        autoPlay={true}
        playsInline={true}
        muted={true}
        ref={videoRef}
      ></video>
      <Flex
        zIndex={videoControlZIndex}
        className="videoControl"
        flexDirection="column"
      >
        <Button
          title={voiceMuted ? t("Unmute") : t("Mute")}
          onClick={handleVoiceMute}
        >
          <FontAwesomeIcon
            icon={voiceMuted ? "microphone-slash" : "microphone"}
          />
        </Button>
        <Button
          title={videoMuted ? t("Enable video") : t("Disable video")}
          onClick={handleVideoMute}
        >
          <FontAwesomeIcon icon={videoMuted ? "video-slash" : "video"} />
        </Button>

        <Button title={t("End call")} onClick={handleStopCall}>
          <FontAwesomeIcon className="stopCallButton" icon="phone" />
        </Button>
      </Flex>
      <Flex zIndex={callButtonZIndex} className="callButtonBox">
        <Zoom height="auto" width="auto" when={!props.inCall}>
          <StartVideoCall inCall={props.inCall} />
        </Zoom>
      </Flex>
      {isPresenter && (
        <Jello delay={500}>
          <Box className="monkey noselect">
            <span role="img" aria-label="currrent-monkey">
              🐒
            </span>
          </Box>
        </Jello>
      )}
    </Flex>
  );
};

const mapStateToVideoBoxProps = (state) => {
  return {
    me: state.me,
    playerTeams: state.game.playerTeams,
    step: state.game.step,
  };
};

export default connect(mapStateToVideoBoxProps, null)(MyVideoBox);
