import SimplePeer from "simple-peer";

import { setInCall, setUserInCall } from "../redux/actions/peerServiceActions";

import { quaranTimeClient } from "../services/quaranTimeClient.js"; //Initialization happened before

class PeerUser {
  constructor(peerService, userid, isInitiator) {
    this.peerService = peerService;
    this.userid = userid;
    this.stream = null;
    this.myStreamSent = false;
    this.connected = false;
    this.peer = new SimplePeer({
      initiator: isInitiator, // if true we are the initiators of the peer connection
      config: {
        allowHalfTrickle: true,
        iceServers: [
          { urls: 'stun:stun.l.google.com:19302' }, 
          { urls: 'stun:global.stun.twilio.com:3478?transport=udp' },
          { urls: "stun:stun.stunprotocol.org:3478" },
          { urls: "stun:stun.sipnet.ru:3478" },
          { urls: "stun:stun1.l.google.com:19302" },
        ],
      },
    });

    this.peer.on("signal", (data) => {
      console.log("signal", data)

      //when initiator true, fires instantly
      quaranTimeClient.signalPeer(userid, data);
    });

    this.peer.on("connect", () => {
      console.log("connect")

      this.connected = true;
    });

    this.peer.on("data", (data) => {
      console.log("data")

      let message = JSON.parse(data);
      switch (message.type) {
        case "call_status":
          if (!message.inCall) this.stopCall();
          break;
        case "draw":
          message.userid = this.userid;
          this.peerService.onDrawMessageListener(message);
          break;
        default:
          break;
      }
    });

    this.peer.on("stream", (stream) => {
      console.log("stream")
      this.stream = stream;
      this.peerService.store.dispatch(setUserInCall(this.userid, true));

      if (this.peerService.inCall && !this.myStreamSent) {
          this.addStream(this.peerService.myStream);
      }
    });

    this.peer.on("error", (err) => {
      console.log("error ", err.code)

      // this.connected = false;
      // this.peerService._tearDownPeerUser(this.userid);
    });

    this.peer.on("close", () => {
      console.log("close")

      this.connected = false;
      this.peerService._tearDownPeerUser(this.userid);
    });
  }

  send(message) {
    if (this.connected) { // && this.peer._channel != null) {
      this.peer.send(message);
    }
  }

  addStream(stream) {
    this.peer.addStream(stream);
    this.myStreamSent = true;
  }

  stopCall() {
    this.peerService.store.dispatch(setUserInCall(this.userid, false));
    if (this.myStreamSent && this.peerService.inCall) {
      this.myStreamSent = false;
      let callStatus = {
        type: "call_status",
        inCall: false,
      };

      this.send(JSON.stringify(callStatus));
      this.peer.removeStream(this.peerService.myStream);
      // this.peerService.myStream
      //   .getTracks()
      //   .forEach((t) => this.peer._senderMap.delete(t)); //** Monkey patch, bad code in simple-peer  */

      // this.peer._remoteStreams.splice(
      //   this.peer._remoteStreams.indexOf(this.peerService.myStream),
      //   1
      // ); //** Monkey patch, super bad code in simple-peer (8 hours to fix this)  */
    }
  }

  peerData(data) {
    console.log("signal recieved", data)
    this.peer.signal(data);
  }
}

class PeerService {
  constructor() {
    this.peerUsers = {};
    this.peerUserList = [];
    this.me = {};
    this.myStream = null;
    this.inCall = false;
    this.videoMuted = true;
    this.voiceMuted = true;

    // This shouldn't be here, should be an extensible interface to any p2p game service
    this.onDrawMessageListener = () => {};
  }

  onDrawMessage(callback) {
    this.onDrawMessageListener = callback;
  }

  sendToAll(message) {
    try {
      message = JSON.stringify(message);
      for (var i = 0; i < this.peerUserList.length; i++) {
        this.peerUserList[i].send(message);
      }
    } catch (error) {}
  }

  setStore(store) {
    this.store = store;
  }

  _setUpPeerUser(userid, isInitiator) {
    if (this.peerUsers[userid]) {
      return;
    }
    let peerUser = new PeerUser(this, userid, isInitiator);
    this.peerUsers[userid] = peerUser;
    this.peerUserList.push(peerUser);
  }

  _tearDownPeerUser(userid) {
    var index = this.peerUserList.findIndex(
      (peerUser) => peerUser.userid === userid
    );
    if (index > -1) {
      this.peerUserList.splice(index, 1); //in place
    }
    this.peerUsers[userid] = undefined;
  }

  startCall() {
    if (this.inCall) {
      return;
    }
    navigator.mediaDevices
      .getUserMedia({
        video: {
          facingMode: "user",
        },
        audio: true,
      })
      .then((stream) => {
        this.videoMuted = false;
        this.voiceMuted = false;
        this.inCall = true;
        this.myStream = stream;
        this.peerUserList.forEach(
          (peerUser) => peerUser && peerUser.addStream(stream)
        );
        this.store.dispatch(setInCall(true));
      })
      .catch(() => {
        // Try again only AUDIO
        navigator.mediaDevices
          .getUserMedia({
            audio: true,
          })
          .then((stream) => {
            this.videoMuted = true;
            this.voiceMuted = false;
            this.inCall = true;
            this.myStream = stream;
            this.peerUserList.forEach(
              (peerUser) => peerUser && peerUser.addStream(stream)
            );
            this.store.dispatch(setInCall(true));
          })
          .catch(() => {
            this.videoMuted = true;
            this.voiceMuted = true;
            this.store.dispatch(setInCall(true));
          });
      })
      .catch(() => {
        this.videoMuted = true;
        this.voiceMuted = true;
        this.inCall = true;
        this.store.dispatch(setInCall(true));
      });
  }

  stopCall() {
    this.store.dispatch(setInCall(false));
    if (!this.inCall) {
      return;
    }
    this.muteVoice(true);
    this.muteVideo(true);
    this.peerUserList.forEach((peerUser) => peerUser && peerUser.stopCall());
    this.myStream.getTracks().forEach((t) => t.stop());
    this.myStream = null;
    this.inCall = false;
  }

  muteVideo(muted) {
    this.videoMuted = muted;
    if (this.myStream)
      this.myStream.getVideoTracks().forEach((t) => (t.enabled = !muted));
  }

  muteVoice(muted) {
    this.voiceMuted = muted;
    if (this.myStream)
      this.myStream.getAudioTracks().forEach((t) => (t.enabled = !muted));
  }

  getMyStream() {
    return this.myStream;
  }

  getUserStream(userid) {
    let peerUser = this.peerUsers[userid];
    if (peerUser) {
      return peerUser.stream;
    }
  }

  hello(me) {
    this.me = me;
  }

  roomUpdateUsers(users) {
    Object.values(users).forEach((user) => {
      if (user && this.me.id && user.id !== this.me.id)
        this._setUpPeerUser(user.id, true);
    });
  }

  userJoined(user) {
    this._setUpPeerUser(user.id, false);
  }

  userLeft(userid) {
    this._tearDownPeerUser(userid);
  }

  peerData(userid, data) {
    let peerUser = this.peerUsers[userid];
    if (peerUser) {
      peerUser.peerData(data);
    }
  }
}

export let peerService = new PeerService();
