import React, { useState, useEffect } from "react";

import {
  Flex,
  Button,
  Input,
  Text,
  Avatar,
  Heading,
  Box,
} from "@chakra-ui/core";

import { quaranTimeClient } from "../../../services/quaranTimeClient.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useTranslation } from "react-i18next";

import { connect } from "react-redux";

import { nanoid } from "nanoid";
import Avatars from "@dicebear/avatars";
import sprites from "@dicebear/avatars-avataaars-sprites";
let options = { base64: true };
let avatars = new Avatars(sprites, options);

const SetYourData = (props) => {
  const { t } = useTranslation();

  const [me, setMe] = useState({
    name: props.me.name,
    id: props.me.id,
    avatarSeed: props.me.avatarSeed,
  });

  const [svg, setSvg] = useState(null);
  const [clicked, setClicked] = useState(false);

  const send = () => {
    if (me.name.replace(/\s/g, "").length === 0) return;
    quaranTimeClient.updateUser(me);
    props.doneSettingHandler();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      send();
    }
  };

  const handleClick = (e) => {
    send();
  };

  const handleFocus = (event) => event.target.select();

  useEffect(() => {
    setSvg(props.me.svg);
    setMe({
      name: props.me.name,
      id: props.me.id,
      avatarSeed: props.me.avatarSeed,
    });
  }, [props.me]);

  const changeAvatar = () => {
    setClicked(true);
    let newSeed = nanoid(10);
    let newSvg = avatars.create(newSeed);

    setMe({
      name: me.name,
      id: me.id,
      avatarSeed: newSeed,
    });
    setSvg(newSvg);
  };

  const handleChange = (e) => {
    if (!clicked) {
      let newSeed = nanoid(10);
      let newSvg = avatars.create(newSeed);

      setMe({
        name: e.target.value,
        id: me.id,
        avatarSeed: newSeed,
      });
      setSvg(newSvg);
    } else {
      setMe({
        name: e.target.value,
        id: me.id,
        avatarSeed: me.avatarSeed,
      });
    }
  };

  return (
    <Box className="setYourData">
      <Flex>
        <Avatar
          title={t("Change avatar")}
          name={me.name}
          mr="16px"
          width="7.5rem"
          height="7.5rem"
          src={svg}
          onClick={changeAvatar}
          position="relative"
        >
          <span className="avatarPointer">
            <FontAwesomeIcon icon="hand-pointer" />
          </span>
        </Avatar>
        <Flex m="0 24px" flexDirection="column">
          <Text fontSize="20px">{t("Your name")}:</Text>
          <Input
            size="lg"
            variant="flushed"
            maxLength="12"
            fontSize="3em"
            height="4rem"
            maxWidth="12ch"
            width={me.name.length +"ch"}
            minWidth="6ch"  
            textAlign="center"
            onFocus={handleFocus}
            value={me.name}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
        </Flex>
      </Flex>
      <Heading marginTop="1rem">
        <Button
          aria-label="Approve name and avatar"
          rounded="full"
          variant="ghost"
          className="okButton"
          onClick={handleClick}
        >
          {t("Done")}
        </Button>
      </Heading>
    </Box>
  );
};

const mapDataToProps = (state) => {
  return {
    me: state.me,
  };
};

export default connect(mapDataToProps, null)(SetYourData);
