import React from "react";
import "./teamviewer.scss";

import { Box, Flex, Heading, Tag, Text, Avatar } from "@chakra-ui/core";

import { connect } from "react-redux";
import Tada from "react-reveal/Tada";
import Swing from "react-reveal/Swing";
import Jump from "react-reveal/Jump";

import { useTranslation } from "react-i18next";

const TeamCard = (props) => {
  const { t } = useTranslation();

  let team = props.team;
  if (!team) {
    return null;
  }
  let userTags = team.players.map((player) => {
    if (player && props.users[player]) {
      let isMe = player === props.me.id;
      let isPresenter = player === props.presenter;
      let name = props.users[player].name
      let display = isMe ? t("You") : name
      if (isPresenter)
        return (
          <Tada key={player}>
            <Tag width="100%" className="playertag presenter" m="0 0 10px 0">
              <Avatar name={name} m="2px" size="xs" src={props.users[player].svg}></Avatar>
              <Text key={name} fontWeight={isMe ? "600" : ""} marginLeft="3px">
                {display}
              </Text>
            </Tag>
          </Tada>
        );
      else
        return (
          <Tag className="playertag" key={player} m="0 0 10px 0">
            <Avatar name={name} m="2px" size="xs" src={props.users[player].svg}></Avatar>
            <Text key={name} fontWeight={isMe ? "600" : ""} marginLeft="3px">
              {display}
            </Text>
          </Tag>
        );
    }
    return null;
  });
  return (
    <Box
      className={
        "teamboxoutline " +
        "team" +
        (team.id + 1) +
        (props.current ? " current" : "")
      }
    >
      <Flex
        className={"teambox"}
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Tag className="teamtag">
          <Heading as="h1" fontSize="1.6rem">
            {t("Team", { id: team.name })}
          </Heading>
        </Tag>
        <Flex alignItems="baseline" marginRight="-24px">
          <Jump spy={team.points}>
            <Text fontWeight="600" fontSize="3rem">
              {team.points}
            </Text>
          </Jump>
          <Text fontWeight="600" fontSize="1.1rem">
            {"/" + props.maxPoints}
          </Text>
        </Flex>

        <Flex flexDirection="column">{userTags}</Flex>
      </Flex>
      {props.topTeam === team.id ? (
        <Swing>
          <img
            alt="currentkings"
            className="coronavirus"
            src="/activity/coronavirus.png"
          />{" "}
        </Swing>
      ) : null}
    </Box>
  );
};

const TeamViewer = (props) => {
  let teamcards = props.teams.map((team) => (
    <TeamCard
      me={props.me}
      key={team.id}
      team={team}
      topTeam={props.topTeam}
      users={props.users}
      current={props.step.currentTeamId === team.id || props.step.isOpenRound}
      presenter={props.step.presenter}
      maxPoints={props.maxPoints}
    />
  ));
  return (
    <Flex wrap="wrap" className="teamviewer" justifyContent="center">
      {teamcards}
    </Flex>
  );
};

const mapGameStateToTeamViewer = (state) => {
  return {
    me: state.me,
    teams: state.game.teams,
    step: state.game.step,
    users: state.room.members,
    topTeam: state.game.topTeam,
    maxPoints: state.game.maxPoints,
  };
};

export default connect(mapGameStateToTeamViewer, null)(TeamViewer);
