import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import Backend from 'i18next-http-backend';
import LanguageDetector from "i18next-browser-languagedetector";

import en from "./en";
import hu from "./hu";  

i18n
  // learn more: https://github.com/i18next/
  // for all options read: https://www.i18next.com
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: en,
      hu: hu
    },
    fallbackLng: "en",
    debug: process.env.NODE_ENV === "development",

    // // have a common namespace used around the full app
    // ns: ["translation"],
    // defaultNS: "translation",

    react: {
      useSuspense: false,
      //wait: true,
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
