import React, { useState, useEffect } from "react";
import "./header.scss";

import {
  Box,
  Flex,
  Text,
  Heading,
  Button,
  Avatar,
  AvatarBadge,
  AvatarGroup,
  useColorMode,
  Tag,
  Divider,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Editable,
  EditableInput,
  EditablePreview,
} from "@chakra-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaMoon } from 'react-icons/fa';
import LanguageIcon from "./LanguageIcon";

import { connect } from "react-redux";

import Tooltip from "rc-tooltip";

import { quaranTimeClient } from "../../services/quaranTimeClient.js";

import { useTranslation } from "react-i18next";

import copy from "copy-to-clipboard";

const SideFlex = (props) => (
  <Flex alignItems="center" paddingTop="1rem" {...props}>
    {props.children}
  </Flex>
);

const SideBox = (props) => (
  <Box alignItems="center" paddingTop="1rem" paddingBottom="4px" {...props}>
    {props.children}
  </Box>
);

function ColorModeButton() {
  const { t } = useTranslation();

  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <Button
      title={colorMode === "light" ? t("Dark mode") : t("Light mode")}
      fontSize="1.5rem"
      rounded="full"
      marginLeft="12px"
      marginBottom="12px"
      aria-label="Dark mode"
      className="headerButton"
      onClick={toggleColorMode}
    >
      {colorMode === "light" ? (
        <FaMoon />
      ) : (
        <FontAwesomeIcon icon="sun" />
      )}
    </Button>
  );
}

function LanguageMenu() {
  const { t, i18n } = useTranslation();

  useEffect(() => {}, [i18n.language]);

  return (
    <Menu placement="bottom-start"  autoSelect={false}>
      <MenuButton
        title={t("Change language")}
        fontSize="1.4rem"
        rounded="full"
        ml="12px"
        className="headerButton"
        aria-label="Language"
        as={Button}
      >
        <LanguageIcon />
      </MenuButton>
      <MenuList className="languageMenu">
        <MenuItem
          key="en"
          className={i18n.language.startsWith("en") ? "selected" : ""}
          onClick={() => i18n.changeLanguage("en")}
        >
          English
        </MenuItem>
        <MenuItem
          key="hu"
          className={i18n.language.startsWith("hu") ? "selected" : ""}
          onClick={() => i18n.changeLanguage("hu")}
        >
          Magyar
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

const CollapseButton = (props) => {
  const { t } = useTranslation();
  return (
    <Button
      title={t("Collapse sidebar")}
      fontSize="1.5rem"
      rounded="full"
      aria-label="Collapse"
      className="headerButton collapseButton"
      onClick={props.collapseHandler}
    >
      <FontAwesomeIcon icon="chevron-left" />
    </Button>
  );
};

const CopyButton = (props) => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);

  const copyToClipboard = (e) => {
    navigator.clipboard.writeText(props.linktext); //.then(succ cb, err cb)
    copy(props.linktext);
    setCopied(true);
  };

  return (
    <Tooltip
      trigger={["click"]}
      overlay={copied ? t("Copied") : t("Copy")}
      placement="right"
    >
      <Button
        title={t("Copy")}
        fontSize="md"
        p="0"
        size="sm"
        rounded="full"
        ml="2px"
        onClick={copyToClipboard}
      >
        <FontAwesomeIcon icon="copy" />
      </Button>
    </Tooltip>
  );
};

const Participants = (props) => {
  const { t } = useTranslation();

  let members = props.room.members;
  let others = Object.values(members).map((member) => {
    if (member && member.id !== props.me.id) {
      return (
        <span key={member.id}>
          <Tooltip
            trigger={["click", "hover"]}
            key={member.id}
            overlay={member.name}
            placement="bottom"
          >
            <Avatar key={member.id} name={member.name} src={member.svg}>
              <AvatarBadge
                size="1em"
                right="3px"
                bg="green.500"
                border="0.15em solid"
              />
            </Avatar>
          </Tooltip>
        </span>
      );
    } else {
      return null;
    }
  });
  return (
    <SideBox>
      <Text fontSize="sm" marginBottom="3px">
        {t("Members")}
      </Text>
      <AvatarGroup size="md" max={5} spacing="-8px">
        <Avatar name={props.me.name} src={props.me.svg}>
          <AvatarBadge
            size="1em"
            right="3px"
            bg="green.500"
            border="0.15em solid"
            key={props.me.id}
          />
        </Avatar>
        {others}
      </AvatarGroup>
    </SideBox>
  );
};

class GroupInfo extends React.Component {
  render() {
    let linktext = "https://quarantime.app/" + this.props.room.id;
    return (
      <Box>
        <Divider paddingTop="1rem" margin="0" />
        {/* <SideFlex>
        <Avatar mr="12px" size="sm" src={this.svg} />
        <Text fontWeight="400" d="inline" fontSize="xl">
          {this.props.room.name}
        </Text>
      </SideFlex> */}
        <SideFlex>
          <Tag
            justifyContent="center"
            p="0 14px 0 14px"
            fontWeight="500"
            size="md"
            flex="1 1 auto"
            rounded="full"
          >
            {linktext}
          </Tag>
          <CopyButton linktext={linktext} />
        </SideFlex>
        <Participants {...this.props} />
        <Divider paddingTop="1rem" margin="0" />
      </Box>
    );
  }
}

const mapStateToGroupInfoProps = (state) => {
  return {
    me: state.me,
    room: state.room,
  };
};

const ConnectedGroupInfo = connect(mapStateToGroupInfoProps, null)(GroupInfo);

const EditableName = (props) => {
  const { t } = useTranslation();

  const [me, setMe] = useState({
    name: props.me.name,
    id: props.me.id,
    avatarSeed: props.me.avatarSeed,
  });

  useEffect(() => {
    setMe({
      name: props.me.name,
      id: props.me.id,
      avatarSeed: props.me.avatarSeed,
    });
  }, [props.me]);

  const send = () => {
    if (me.name.replace(/\s/g, "").length === 0) {
      setMe({
        name: props.me.name,
      });
      return;
    };
    quaranTimeClient.updateUser(me);
  };

  const handleChange = (value) => {
    setMe({
      name: value,
    });
  };

  const onCancel = () => {
    setMe({
      name: props.me.name,
    });
  }

  return (
    <Editable
      width="100%"
      onChange={handleChange}
      onSubmit={send}
      onCancel={onCancel}
      defaultValue={me.name}
      value={me.name}
      placeholder={t("Your name")}
    >
      <EditablePreview width="100%" />
      <EditableInput maxLength="12"/>
    </Editable>
  );
};

const Header = (props) => {
  return (
    <Box className="header" p="0 16px 0 16px">
      <SideFlex>
        <Heading as="h1" fontSize={["2xl", "2xl", "2xl", "2xl"]}>
          QuaranTime
        </Heading>
        <Box flex="1 1 auto" />
        <CollapseButton collapseHandler={props.collapseHandler} />
      </SideFlex>
      <SideFlex>
        <Avatar name={props.me.name} mr="12px" size="lg" src={props.me.svg} />
        <Text flex="1" fontWeight="500" d="inline" fontSize="2xl">
          <EditableName me={props.me} />
        </Text>
        <Flex flexDirection="column">
          <ColorModeButton />
          <LanguageMenu />
        </Flex>
      </SideFlex>
      <ConnectedGroupInfo />
    </Box>
  );
};
const mapStateToProps = (state) => {
  return {
    me: state.me,
  };
};

export default connect(mapStateToProps, null)(Header);
