// Najib, 2020

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import './index.scss';

import "rc-tooltip/assets/bootstrap.css";
import "simplebar/dist/simplebar.min.css";

import App from "./App";

import * as serviceWorker from "./serviceWorker";
import {
  ColorModeScript,
  ChakraProvider,
  extendTheme,
} from "@chakra-ui/core";

import "./i18n"; // Translation
import metadata from './metadata.json';

let quarantimeVersion = `Version ${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision} ${metadata.buildTag}`
document.getElementsByTagName("head")[0].innerHTML += `<meta build="${quarantimeVersion}" />`;
console.log(quarantimeVersion)

function delay(t, v) {
  return new Promise(function(resolve) { 
      setTimeout(resolve.bind(null, v), t)
  });
}

Promise.prototype.delay = function(t) {
  return this.then(function(v) {
      return delay(t, v);
  });
}

// const customTheme = extendTheme({
//   textStyles: {
//     heading:
//       'Lobster, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
//     body:
//       '"Baloo Thambi 2", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
//     mono:
//       'SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace',
//   },
//   // breakpoints: ["980px", "1280px", "1690px", "1920px"],

// });

const theme = extendTheme({
  components: {
    Heading: {
      baseStyle: {
        fontFamily: 'Lobster, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      }
    }
  },
  styles: {
    global: {
      "html, body": {
        fontFamily: '"Baloo Thambi 2", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      },
    },
  },
})

const darkMode = localStorage.getItem('chakra-ui-color-mode');
if(darkMode === null){
  localStorage.setItem('chakra-ui-color-mode', 'dark');
}

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
        <ColorModeScript initialColorMode="dark" />
        <App />
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register(); // TODO register PROD PWA

serviceWorker.unregister()
