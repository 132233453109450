import React, { useState, useEffect } from "react";
import "./minisplash.scss";

import { Flex, Image, Heading, Box } from "@chakra-ui/core";

import { connect } from "react-redux";

import SetYourData from "./SetYourData";
import InvitePeople from "./InvitePeople";

import { useTranslation } from "react-i18next";

// import Zoom from "react-reveal/Zoom";
import Zoom from "../../Animations/Zoom";

const QActivity = () => {
  const { t } = useTranslation();

  return (
    <Flex
      paddingBottom="1rem"
      textAlign="center"
      flexDirection="column"
      margin="auto"
    >
      <Flex margin="auto" justifyContent="center" flexWrap="wrap">
        <Heading
          fontWeight="400"
          fontSize="4em"
          display="flex"
          alignItems="baseline"
        >
          {t("Welcome2")}
        </Heading>
        <Image ml="40px" height="120px" src="/activity/charade.svg" />
      </Flex>
    </Flex>
  );
};

let globReady = false; // because of remounts

const MiniSplash = (props) => {
  // const { t } = useTranslation();
  const [ready, setReady] = useState(globReady);

  useEffect(() => {
    Promise.resolve()
      .delay(800)
      .then(() => {
        setReady(true);
        globReady = true;
      });
  });

  return (
    <Flex className="miniSplash" p="2rem 0" flexDirection="column">
      <Box margin="auto">
        <Zoom when={ready && (!props.doneSetting || props.usersCount < 2)}>
          <Zoom when={props.usersCount < 2}>
            <QActivity></QActivity>
            <InvitePeople roomid={props.roomid}></InvitePeople>
            {/* <Box fontWeight="500" marginTop="1rem" textAlign="center">
              <Text fontSize="1rem">{t("New games soon")}</Text>
            </Box> */}
          </Zoom>
          <Zoom when={!props.doneSetting && props.usersCount > 1}>
            <SetYourData
              doneSettingHandler={props.doneSettingHandler}
            ></SetYourData>
          </Zoom>
        </Zoom>
      </Box>
    </Flex>
  );
};

const mapDataToProps = (state) => {
  return {
    me: state.me,
    usersCount: state.room.memberCount,
    roomid: state.room.id,
  };
};

export default connect(mapDataToProps, null)(MiniSplash);
