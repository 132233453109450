import React, { useState, useEffect } from "react";
import { useSpring, animated as a } from "react-spring";

function Zoom(props) {
  const [zoomed, setZoomed] = useState(false);
  const [displayed, setDisplayed] = useState(false);

  let delay = 200;
  if (props.delay !== undefined) {
    delay = props.delay + delay;
  }

  useEffect(() => {
    function zoomOut() {
      setZoomed(false);
      Promise.resolve()
        .delay(200)
        .then(() => setDisplayed(false));
    }

    function zoomIn() {
      Promise.resolve()
        .delay(delay)
        .then(() => setDisplayed(true))
        .then(() => setZoomed(true));
    }

    if (props.when !== zoomed) {
      if (props.when) {
        zoomIn();
      } else {
        zoomOut();
      }
    }
  }, [props.when, zoomed]);

  const { transform, display } = useSpring({
    display: displayed ? "block" : "none",
    transform: `scale(${zoomed ? 1.0 : 0.0}, ${zoomed ? 1.0 : 0.0})`,
    config: { mass: 3, tension: 5000, friction: 500 },
  });
  return (
    <a.div
      style={{
        transformOrigin: props.transformOrigin || "initial",
        height: props.height || "100%",
        width: props.width || "100%",
        display: display,
        transform: transform.interpolate((t) => `${t}`),
      }}
    >
      {props.children}
    </a.div>
  );
}

export default Zoom;
