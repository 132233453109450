import React, { useRef, useState, useEffect } from "react";

import { Flex, Text, Button, Box } from "@chakra-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";

import { peerService } from "../../services/peerService.js"; //Initialization by import

import { Avatar } from "@chakra-ui/core";

import { useTranslation } from "react-i18next";

import Jello from "react-reveal/Jello";

const VideoBox = (props) => {
  const { t } = useTranslation();

  const videoRef = useRef(null);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!props.user) {
      if (props.largeVideo) props.toggleLargeVideo(null);
      return;
    }
    let peerUser = props.peerStuff.users[props.user.id];
    if (peerUser && peerUser.inCall && props.peerStuff.inCall && !initialized) {
      if ("srcObject" in videoRef.current) {
        videoRef.current.srcObject = peerService.getUserStream(props.user.id);
        videoRef.current.play().then();
      } else {
        videoRef.current.src = window.URL.createObjectURL(
          peerService.getUserStream(props.user.id)
        ); // older browsers
        videoRef.current.play().then();
      }
      setInitialized(true)
    }
  }, [props.peerStuff.inCall, props.peerStuff.users, props.user]);

  if (!props.user) {
    return null;
  }
  let teamid = props.playerTeams[props.user.id];
  let isPresenter = props.step.presenter === props.user.id;
  return (
    <Flex
      className={
        "videobox inCall " + "team" + (teamid + 1) + (isPresenter ? " presenter" : "")
      }
    >
      <video autoPlay={true} playsInline={true} ref={videoRef}></video>
      <Flex className="videoControl" flexDirection="column">
        <Button
          title={props.largeVideo ? t("Small video") : t("Large video")}
          onClick={() => {
            props.largeVideo
              ? props.toggleLargeVideo(null)
              : props.toggleLargeVideo(props.user.id);
          }}
        >
          <FontAwesomeIcon icon={props.largeVideo ? "compress" : "expand"} />
        </Button>
      </Flex>
      <Flex className="videoAvatarName noselect">
        <Avatar
          size="md"
          key={props.user.name}
          src={props.user.svg}
          name={props.user.name}
        />
        <Text>{props.user.name}</Text>
      </Flex>
      {isPresenter && (
        <Jello delay={500}>
          <Box className="monkey noselect">
            <span role="img" aria-label="currrent-monkey">
              🐒
            </span>
          </Box>
        </Jello>
      )}
    </Flex>
  );
};

const mapStateToVideoBoxProps = (state) => {
  return {
    playerTeams: state.game.playerTeams,
    step: state.game.step,
    peerStuff: state.peerStuff,
  };
};

export default connect(mapStateToVideoBoxProps, null)(VideoBox);
