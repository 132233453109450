import React, { useState, useEffect } from "react";
import "./splash.scss";

import {
  Box,
  Flex,
  Heading,
  Button,
  Stack,
  Spinner,
  LightMode,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/core";

import { BsChevronDown } from 'react-icons/bs';

import { quaranTimeClient } from "../services/quaranTimeClient.js";

import { useTranslation } from "react-i18next";

function SplashLanguageMenu(props) {
  const { t, i18n } = useTranslation();
  useEffect(() => {}, [i18n.language]);

  return (
    <Box marginTop="1rem" className="splashLanguageMenu">
      <Menu fixed isLazy placement="bottom" >
        <MenuButton
          title={t("Change language")}
          fontSize="1.4rem"
          rounded="full"
          aria-label="Language"
          as={Button}
          rightIcon={<BsChevronDown/>}
          >
          {t(i18n.language.substring(0, 2))}
        </MenuButton>
        <MenuList className="languageMenu">
          <MenuItem
            className={i18n.language.startsWith("en") ? "selected" : ""}
            onClick={() => i18n.changeLanguage("en")}
          >
            English
          </MenuItem>
          <MenuItem
            className={i18n.language.startsWith("hu") ? "selected" : ""}
            onClick={() => i18n.changeLanguage("hu")}
          >
            Magyar
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
}

const Splash = () => {
  // if logged in push to first group. Get them from localstorage
  const { t } = useTranslation();

  useEffect(()=>{
    setTimeout(()=>{
      window.dispatchEvent(new Event('resize'));
    }, 1200)
  },[])

  const [isWaitingForRoom, setIsWaitingForRoom] = useState(false);

  const handleCreateRoomClick = () => {
    if (!isWaitingForRoom) setIsWaitingForRoom(true);
    quaranTimeClient.newRoom();
  };

  return (
    <LightMode>
      <Flex alignItems="center" justifyContent="center">
        <Box paddingBottom="10vh" textAlign="center" position="absolute">
          <Box className="front">
            <Heading
              as="h1"
              size="4xl"
              textShadow="2px 2px 2px #ffffff;"
              fontSize={["50px", "80px", "120px", "160px"]}
              lineHeight="normal"
            >
              QuaranTime
            </Heading>
            <Heading
              textShadow="1px 1px 2px #ffffff;"
              fontWeight="500"
              fontSize={["40px", "50px", "50px", "60px"]}
              lineHeight="normal"
            >
              {t("Face-time gaming")}
            </Heading>
            <Stack m="4rem 0 2rem 0" alignItems="center" spacing={4}>
              <Button
                className="mainButton"
                rounded="full"
                size="lg"
                variant="solid"
                onClick={handleCreateRoomClick}
              >
                {t("Create room")}
                {isWaitingForRoom && <Spinner marginLeft="10px" size="md" />}
              </Button>
              <SplashLanguageMenu />
            </Stack>
          </Box>
        </Box>

        <Flex flex="1" className="background" flexWrap="nowrap" h="calc(100vh)">
          <Box flex="1"></Box>
          <Box flex="1"></Box>
          <Box flex="1"></Box>
          <Box flex="1"></Box>
          <Box flex="1"></Box>
        </Flex>
      </Flex>
      <Box
        color="white"
        position="absolute"
        bottom="0"
        right="0"
        paddingRight="1rem"
      >
        <Text>Made by Najib Ghadri. QuaranTime © {new Date().getFullYear()}</Text>
      </Box>
    </LightMode>
  );
};

export default Splash;
