import store from "../redux/store/store";

import {
  card as cardAction,
  game_update,
} from "../redux/actions/activityActions";

export class ActivityClient {
  setSocketListeners(socket) {
    this.socket = socket;

    socket.on("CARD", (card) => {
      store.dispatch(cardAction(card));
    });

    socket.on("GAME_UPDATE", (gameUpdate) => {
      store.dispatch(game_update(gameUpdate));
    });
  }

  startGame(gameLanguage) {
    this.socket.emit("START_GAME", {gameLanguage: gameLanguage});
  }

  revealCard() {
    this.socket.emit("REVEAL_CARD");
  }

  setResult(result) {
    this.socket.emit("SET_RESULT", result);
  }
}

export let activityClient = new ActivityClient();
