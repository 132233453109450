import React, { useState, useEffect } from "react";
import "./sidebar.scss";

import { Box, Flex, Button } from "@chakra-ui/core";

import Chat from "./Chat";
import Header from "./Header";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

// const Groups = () => ({});

const OpenSidebarButton = (props) => {
  const { t } = useTranslation();

  return (
    <Box position="fixed" top="10px" left="16px" zIndex="1">
      <Button
        title={t("Open sidebar")}
        size="md"
        rounded="full"
        aria-label="Open sidebar"
        className="openSidebarButton"
        onClick={props.openHandler}
      >
        <FontAwesomeIcon icon="chevron-right" />
      </Button>
    </Box>
  );
};
const Sidebar = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1200px)" });
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    if (isTabletOrMobile) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [isTabletOrMobile]);

  if (collapsed) {
    return (
      <OpenSidebarButton
        openHandler={() => {
          setCollapsed(false);
        }}
      />
    );
  } else {
    return (
      <Box minWidth={!collapsed && !isTabletOrMobile ? "304px" : "initial"}>
          <Flex flexDirection="column" className="sidebar">
            <Header
              collapseHandler={() => {
                setCollapsed(true);
              }}
            />
            <Chat />
          </Flex>
      </Box>
    );
  }
};

export default Sidebar;
