let hu = {
    translation: {
      "Face-time gaming": "Face-time gaming",
      "Create room": "Új szoba",
      "Welcome1": "Üdv a",
      "Welcome2": "QuActivity",
      "Members": "Tagok",
      "Type a message": "Írj egy üzenetet...",
      "Your name": "Neved",
      "New games soon": "Hamarosan új játékok!",
      "alone": "Még egyedül vagy. Hívd meg a barátaid:",
      "You": "Te",
      "Change avatar": "Nyomd meg és válts",

      "Let's Start": "Játsszunk!",
      "Start videochat": "Videochat \nkezdése",
      "Done": "Kész",

      "hu": "Magyar",
      "en": "Angol",
      "Word dict": "Szótár:",
      
      // button titles
        "Collapse sidebar": "Oldalsáv bezárása",
        "Open sidebar": "Oldalsáv kinyitása",
        "Light mode": "Világos mód",
        "Dark mode": "Sötét mód",
        "Change language": "Nyelv választása",
        "Copy": "Másolás",
        "Copied": "Másolva!",
        "Send emoji": "Küljd emojit",
        "Send message": "Üzenet küldése",
        //Drawing board
        "Toggle wide canvas": "Széles rajztábla",
        "Select color": "Szín választása",
        "Change brush size": "Ecset méret választása",
        "Clear board": "Tábla törlése",
        "Cleaned": "{{name}} törölt",
        // Phone
        "End call": "Hívás vége",
        "Disable video": "Videó kikapcsolása",
        "Enable video": "Videó engedélyezése",
        "Mute": "Mikrofon némítása",
        "Unmute": "Mikrofon engedélyezése",
        "Large video": "Nagy videó",
        "Small video": "Kis videó",

      // Activity
      "Charade": "Mutogatás",
      "Explanatory": "Körülírás",
      "Pictionary": "Rajzolás",
   
      "open_exclaim" : "Nyílt kör! \n",
      "present_i": "Te adsz elő!",
      "present_them": "{{presenter}} ad elő",

      "present_i_to_them": "Te adsz elő, \n{{guesser}} számára",
      "present_them_to_me": "{{presenter}} ad elő neked!",
      "present_them_to_them": "{{presenter}} ad elő, \n{{guesser}} számára",

      "Open round": "Nyílt kör",
      "2 points for current team": "2 pont az előadó csapatának",
      "4 points for winner team": "4 pont a nyerő csapatnak",
      "Word": "Szó:",

      "Reveal": "Kártya felfedése",
      "Winner": "Válassz nyertest:",
      "Fail": "Nem sikerült",
      "Success": "Sikerült",

      "points": "{{count}} pont",
      "Team": "Csapat {{id}}",
      
    }
  };
 
  export default hu;
  