import React, { useState, useEffect } from "react";
import "./gameboard.scss";

import {
  Flex,
  Box,
  Heading,
  Button,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/core";

import { BsChevronDown } from 'react-icons/bs';


import ActivityBoard from "./ActivityBoard";
import DrawingBoard from "./DrawingBoard";
import TeamViewer from "./TeamViewer";
import MiniSplash from "./MiniSplash";

import { connect } from "react-redux";
import { activityClient } from "../services/activityClient.js";

// import Zoom from "react-reveal/Zoom";
import Zoom from "../Animations/Zoom";
import VideoBox from "../VideoBoard/VideoBox";

import { useTranslation } from "react-i18next";

function GameLanguageMenu(props) {
  const { t } = useTranslation();

  return (
    <Menu placement="bottom-start" autoSelect={false}>
      <MenuButton
        title={t("Change language")}
        fontSize="1.3rem"
        rounded="full"
        ml="12px"
        className="headerButton"
        aria-label="Language"
        as={Button}
        rightIcon={<BsChevronDown/>}
      >
        {t(props.gameLanguage)}
      </MenuButton>
      <MenuList className="languageMenu">
        <MenuItem
          className={props.gameLanguage.startsWith("en") ? "selected" : ""}
          onClick={() => props.changeGameLanguage("en")}
        >
          {t("en")}
        </MenuItem>
        <MenuItem
          className={props.gameLanguage.startsWith("hu") ? "selected" : ""}
          onClick={() => props.changeGameLanguage("hu")}
        >
          {t("hu")}
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

const StartTheGame = () => {
  const { t, i18n } = useTranslation();

  const [gameLanguage, setGameLang] = useState(i18n.language.substring(0, 2));

  useEffect(() => {
    setGameLang(i18n.language.substring(0, 2));
  }, [i18n.language]);

  const changeGameLanguage = (lang) => {
    setGameLang(lang);
  };

  return (
    <Box>
      <Heading marginTop="1rem">
        <Button
          onClick={() => {
            activityClient.startGame(gameLanguage);
          }}
          className="startGameButton"
          rounded="full"
          variant="solid"
        >
          {t("Let's Start")}
        </Button>
      </Heading>
      <Flex
        margin="auto"
        alignItems="center"
        marginTop="2rem"
        marginBottom="-2rem"
      >
        <Text marginLeft="0.5rem" fontSize="1.3rem" fontWeight="500">
          {t("Word dict")}
        </Text>
        <GameLanguageMenu
          gameLanguage={gameLanguage}
          changeGameLanguage={changeGameLanguage}
        />
      </Flex>
    </Box>
  );
};

const GameBoard = (props) => {
  const [doneSetting, setDoneSetting] = useState(false);

  const doneSettingHandler = () => {
    setDoneSetting(true);
  };

  const [canvasFullscreen, setCanvasFullScreen] = useState(false);

  const toggleCanvasFullscreen = () => {
    setCanvasFullScreen(!canvasFullscreen);
  };

  let peerUser = props.peerStuff.users[props.largeVideoUser];
  let largeVideo =
    props.largeVideoUser &&
    props.users[props.largeVideoUser] &&
    peerUser &&
    peerUser.inCall;

  return (
    <Flex className="gameboard">
      {!canvasFullscreen && (
        <Flex
          marginTop="2rem"
          flexDirection="column"
          flex="1"
          justifyContent="flex-end"
        >
          <Box margin="auto" zIndex="1">
            <Zoom delay={300} when={props.usersCount < 2 || !doneSetting}>
              <MiniSplash doneSetting={doneSetting} doneSettingHandler={doneSettingHandler} />
            </Zoom>

            <Zoom
              when={
                props.playersCount > 1 &&
                doneSetting &&
                props.gameStatus === "stopped"
              }
            >
              <StartTheGame />
            </Zoom>
            <Zoom
              when={
                props.playersCount > 1 &&
                doneSetting &&
                props.gameStatus === "started"
              }
            >
              <ActivityBoard />
            </Zoom>
          </Box>

          <TeamViewer />
        </Flex>
      )}

      <Flex zIndex="2" flex={largeVideo && !canvasFullscreen ? "none" : "1"}>
        <Zoom when={true}>
          <DrawingBoard
            needCollapse={largeVideo}
            isFullscreen={canvasFullscreen}
            toggleFullscreen={toggleCanvasFullscreen}
          />
        </Zoom>
      </Flex>

      {largeVideo && (
        <Flex className="largeVideo" flex="1">
          <Zoom when={largeVideo}>
            <VideoBox
              user={props.users[props.largeVideoUser]}
              largeVideo={true}
              toggleLargeVideo={props.toggleLargeVideo}
            ></VideoBox>
          </Zoom>
        </Flex>
      )}
    </Flex>
  );
};

const mapGameStateGameBoard = (state) => {
  return {
    gameStatus: state.game.status,
    inCall: state.peerStuff.inCall,
    usersCount: state.room.memberCount,
    playersCount: state.game.playersCount,
    users: state.room.members,
    peerStuff: state.peerStuff,
  };
};

export default connect(mapGameStateGameBoard, null)(GameBoard);
