import React, { useState, useEffect } from "react";
import "./board.scss";

import { connect } from "react-redux";

import VideoBoard from "../VideoBoard";
import GameBoard from "../GameBoard";

import { Flex } from "@chakra-ui/core";

const Board = (props) => {
  const [largeVideoUser, setLargeVideoUser] = useState(null);
  
  const toggleLargeVideo = (userid) => {
    setLargeVideoUser(userid)
  }

  useEffect(()=>{
    if(!props.peerStuff.inCall) setLargeVideoUser(null)
  }, [props.peerStuff.inCall])
  
  return (
    <Flex
      className="board"
      width="100%"
      flexDirection="column"
      backgroundColor="transparent"
    >
      <VideoBoard largeVideoUser={largeVideoUser} toggleLargeVideo={toggleLargeVideo}/>

      <GameBoard largeVideoUser={largeVideoUser} toggleLargeVideo={toggleLargeVideo}/>
    </Flex>
  );
};

const mapStateToProps = (state) => {
  return {
    peerStuff: state.peerStuff,
  };
};

export default connect(mapStateToProps, null)(Board);
