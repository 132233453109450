import React from "react";
import "./room.scss";

import LoadingSplash from "./LoadingSplash";
import Sidebar from "../Sidebar";
import Board from "../Board";


import {
  Box,
  Flex,
} from "@chakra-ui/core";

import Avatars from '@dicebear/avatars';
import sprites from '@dicebear/avatars-jdenticon-sprites';
import { nanoid } from "nanoid";

import { peerService } from "../services/peerService.js";
import { quaranTimeClient } from "../services/quaranTimeClient.js";

let options = {base64: true, width: 80, height: 80};
let avatars = new Avatars(sprites, options);
let svg = avatars.create(nanoid(7));

class Room extends React.Component {

  componentDidMount(){
    quaranTimeClient.joinRoom(this.props.match.params.roomid);
  }

  componentDidUpdate(){
    // not from splash most likely
    quaranTimeClient.joinRoom(this.props.match.params.roomid); 
  }

  componentWillUnmount(){
    peerService.stopCall()
  }

  render() {
    return (
      <Flex className="room">
        {this.props.history.location.state ? null : <LoadingSplash/>}
        
        <Sidebar/>
        <Board/>
        <Box
          backgroundImage={' url("' + svg + '")'}
          className="room-background"
        />
      </Flex>
    );
  }
}

export default Room;