import React, { useState } from "react";

import { Button, Tag, Box, Text } from "@chakra-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "rc-tooltip";

import { useTranslation } from "react-i18next";

import copy from "copy-to-clipboard";

const CopyButton = (props) => {
  const { t } = useTranslation();

  const [copied, setCopied] = useState(false);

  const copyToClipboard = (e) => {
    navigator.clipboard.writeText(props.linktext); //.then(succ cb, err cb)
    copy(props.linktext);

    setCopied(true);
  };

  return (
    <Tooltip
      trigger={["click"]}
      overlay={copied ? t("Copied") : t("Copy")}
      placement="bottom"
    >
      <Button
        title={t("Copy")}
        size="sm"
        ml="2px"
        height="3rem"
        fontSize="2rem"
        onClick={copyToClipboard}
        verticalAlign="bottom"
      >
        <FontAwesomeIcon icon="copy" />
      </Button>
    </Tooltip>
  );
};

const InvitePeople = (props) => {
  const { t } = useTranslation();

  let linktext = "https://quarantime.app/" + props.roomid;

  return (
    <Box textAlign="center" paddingBottom="1rem" flexDirection="column">
      <Text fontSize="1.5rem" marginBottom="1rem">
        {t("alone")}
      </Text>
      <Box marginBottom="7px" display="inline-block">
        <Tag
          fontSize="1.2em"
          p="0 20px"
          height="3rem"
          fontWeight="500"
          size="md"
        >
          {linktext}
        </Tag>
        <CopyButton linktext={linktext} />
      </Box>
    </Box>
  );
};

export default InvitePeople;
