import React, { useState, useEffect } from "react";

import { Button, Spinner, Box } from "@chakra-ui/core";

import { useTranslation } from "react-i18next";

import { peerService } from "../../services/peerService.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StartVideoCall = (props) => {
  const { t } = useTranslation();

  const [waitingForCall, setWaitingForCall] = useState(false);

  useEffect(()=>{
    if(props.inCall){
      setWaitingForCall(false)
    }
  }, [props.inCall])

  const handleClick = () => {
    setWaitingForCall(true)
    peerService.startCall();
  }

  return (
    <Box paddingBottom="1rem">
      <Button
        onClick={handleClick}
        className="callButton"
        rounded="full"
        variant="solid"
        whiteSpace="pre-line"
        flexWrap="wrap"
        margin="0 1rem"
      >
        <p display="inline-block">{t("Start videochat")}</p>
        {waitingForCall  ? <Spinner marginLeft="10px" size="md" /> : <FontAwesomeIcon icon="video" />}
      </Button>
    </Box>
  );
};

export default StartVideoCall;
