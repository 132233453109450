let en = {
    translation: {
      "Face-time gaming": "Face-time gaming",
      "Create room": "Create room",
      "Welcome1": "Welcome to",
      "Welcome2": "QuActivity",
      "Members": "Members",
      "Type a message": "Type a message...",
      "Your name": "Your name",
      "New games soon": "New games coming soon!",
      "alone": "You are alone here. Invite some friends to party:",
      "You": "You",
      "Change avatar": "Click to change",

      "Let's Start": "Let's Start!",
      "Start videochat": "Start\nvideochat",
      "Done": "Done",

      "hu": "Hungarian",
      "en": "English",
      "Word dict": "Words:",

      // button titles: 
        "Collapse sidebar": "Collapse sidebar",
        "Open sidebar": "Open sidebar",
        "Light mode": "Light mode",
        "Dark mode": "Dark mode",
        "Change language": "Change language",
        "Copy": "Copy",
        "Copied": "Copied",
        "Send emoji": "Send emoji",
        "Send message": "Send message",
        //Drawing board
        "Toggle wide canvas": "Toggle wide canvas",
        "Select color": "Select color",
        "Change brush size": "Change brush size",
        "Clear board": "Clear board",
        "Cleaned": "{{name}} cleaned",
        // Phone
        "End call": "End call",
        "Disable video": "Disable video",
        "Enable video": "Enable video",
        "Mute": "Mute voice",
        "Unmute": "Unmute voice",
        "Large video": "Large video",
        "Small video": "Small video",

      // Activity
      "Charade": "Act",
      "Explanatory": "Explain",
      "Pictionary": "Draw",

      "open_exclaim": "Open round! \n",
      "present_i": "You present!",
      "present_them": "{{presenter}} presents", 

      "present_i_to_them": "You present \nto {{guesser}}!",
      "present_them_to_me": "{{presenter}} presents \nto you!",
      "present_them_to_them": "{{presenter}} presents \nto {{guesser}}",

      "Open round": "Open round",
      "2 points for current team": "2 points for the presenter's team",
      "4 points for winner team": "4 points for winner team",
      "Word": "Word:",

      "Reveal": "Reveal card",
      "Winner": "Choose winner:",
      "Fail": "Fail",
      "Success": "Success",

      "points": "{{count}} points",
      "Team": "Team {{id}}",
      
    }
  };
  
  export default en;
  