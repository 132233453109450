import React from "react";
import "./app.scss";

import Splash from "../Splash";
import Room from "../Room";

import { Router, Switch, Route } from "react-router-dom";

import { Provider } from "react-redux";
import store from "../redux/store/store";
import { peerService } from "../services/peerService.js";
import { quaranTimeClient } from "../services/quaranTimeClient.js"; //Initialization by import

import history from "../services/history";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faMoon,
  faSun,
  faCog,
  faChevronLeft,
  faChevronRight,
  faCopy,
  faPaperPlane,
  faHandPointer,
  faTrashAlt,
  faCircle,
  faVideo,
  faPhone,
  faExpand,
  faCompress,
  faMicrophoneSlash,
  faMicrophone,
  faVideoSlash,
  faLanguage,
} from "@fortawesome/free-solid-svg-icons";

peerService.setStore(store);

library.add(
  faMoon,
  faMicrophoneSlash,
  faMicrophone,
  faTrashAlt,
  faExpand,
  faCompress,
  faCircle,
  faPhone,
  faVideoSlash,
  faVideo,
  faSun,
  faCog,
  faChevronLeft,
  faChevronRight,
  faCopy,
  faPaperPlane,
  faHandPointer,
  faLanguage
);

// TODO
// SockIO localStorage of rooms
// serviceWorker.register() PWA it
// Activity Game Lazy Loading?
// localstorage get groups
// participants list - clickable

class App extends React.Component {
  componentDidMount() {
    quaranTimeClient.connectSocket();
  }

  render() {
    return (
      <Provider store={store}>
        <Router history={history}>
          <Switch>
            <Route exact path="/">
              <Splash />
            </Route>
            <Route
              path="/:roomid"
              render={(matchProps) => <Room {...matchProps} />}
            />
          </Switch>
        </Router>
      </Provider>
    );
  }
}

export default App;
