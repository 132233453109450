import React, { useEffect, useState } from "react";
// import "../../Splash/splash.scss";
import { useTranslation } from 'react-i18next';

import {
  Box,
  Flex,
  Heading,
  LightMode,
  Text,
} from "@chakra-ui/core";
import Fade from "react-reveal/Fade";

const LoadingSplash = () => {
  // if logged in push to first group. Get them from localstorage
  const { t } = useTranslation();

  const [done, setDone] = useState(false);

  useEffect(() => {
    Promise.resolve()
      .delay(1600)
      .then(() => {
        setDone(true);
      });
  }, [done]);

  return (
    <Box position="fixed" zIndex="100">
      <Fade collapse duration={300} when={!done}>
        <Box width="100vw" height="100vw" top="0" left="0">
          <LightMode>
            <Flex
              position="relative"
              alignItems="center"
              justifyContent="center"
            >
              <Box paddingBottom="10vh" textAlign="center" position="absolute">
                <Box className="front">
                  <Heading
                    textShadow="2px 2px 2px #ffffff;"
                    as="h1"
                    fontSize={["50px", "80px", "120px", "160px"]}
                    height={110}
                  >
                    QuaranTime
                  </Heading>
                  <Heading
                    textShadow="1px 1px 2px #ffffff;"
                    fontWeight="500"
                    as="h1"
                    fontSize={["40px", "50px", "50px", "60px"]}
                  >
                   {t("Face-time gaming")}
                  </Heading>
                </Box>
              </Box>
              <Flex
                flex="1"
                className="background"
                flexWrap="nowrap"
                h="calc(100vh)"
              >
                <Box flex="1 1 0"></Box>
                <Box flex="1 1 0"></Box>
                <Box flex="1 1 0"></Box>
                <Box flex="1 1 0"></Box>
                <Box flex="1 1 0"></Box>
              </Flex>
              <Box
                color="white"
                position="absolute"
                bottom="0"
                right="0"
                paddingRight="1rem"
              >
                <Text>Made by Najib Ghadri. QuaranTime © {new Date().getFullYear()}</Text>
              </Box>
            </Flex>
          </LightMode>
        </Box>
      </Fade>
    </Box>
  );
};

export default LoadingSplash;
